import { ITextStyle } from 'pixi.js';

export const HIGH_CHANCE_MODE_COUNTER_POS_X = 415;
export const HIGH_CHANCE_MODE_COUNTER_POS_Y = 150;

export const HIGH_CHANCE_MODE_COUNTER_ANIMATION_SCALE = 1.3;
export const HIGH_CHANCE_MODE_COUNTER_ANIMATION_DELAY = 500;
export const HIGH_CHANCE_MODE_COUNTER_ANIMATION_LOOP = false;

const font = 'NotoSans-SemiCondensedBold';

export const textStyle: Partial<ITextStyle> = {
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: ['#b3ffff', '#2078ff', '#407dd4', '#60ffff', '#2d28ff', '#064fff', '#4053ff', '#00c0ff'],
  fillGradientStops: [0.25, 0.38, 0.5, 0.53, 0.55, 0.59, 0.66, 0.8],
  fontFamily: font,
  fontSize: 60,
  fontWeight: '800',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#ffffff',
  strokeThickness: 8,
};

export const spinsStyle: Partial<ITextStyle> = {
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: ['#b3ffff', '#2078ff', '#407dd4', '#60ffff', '#2d28ff', '#064fff', '#4053ff', '#00c0ff'],
  fillGradientStops: [0.25, 0.38, 0.5, 0.53, 0.55, 0.59, 0.66, 0.8],
  fontFamily: font,
  fontSize: 60,
  fontWeight: '800',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#ffffff',
  strokeThickness: 8,
};

export const multiPlierStyle: Partial<ITextStyle> = {
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 0,
  dropShadowDistance: 4,
  fill: ['#b3ffff', '#2078ff', '#407dd4', '#60ffff', '#2d28ff', '#064fff', '#4053ff', '#00c0ff'],
  fillGradientStops: [0.25, 0.38, 0.5, 0.53, 0.55, 0.59, 0.66, 0.8],
  fontFamily: font,
  fontSize: 80,
  fontWeight: '800',
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#ffffff',
  strokeThickness: 8,
};
